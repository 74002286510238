import 'swiper/scss';
import 'react-typist/dist/Typist.css';
import '../styles/globals.scss';
import '../styles/cookie-manager/index.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'next-themes';
import { appWithTranslation } from 'next-i18next';
import { ScrollProvider } from '../contexts/scroll';
import { Suspense } from 'react';
// import { CookieProvider } from '@tobiaswaelde/cookie-manager/context';
// import { useCookieConfig } from '../config/cookies';
// import { Suspense } from 'react';

const fonts = [
	'/fonts/Nunito/static/Nunito-Regular.ttf',
	'/fonts/Nunito/static/Nunito-Bold.ttf',
	'/fonts/Poppins/Poppins-Light.ttf',
	'/fonts/Poppins/Poppins-Medium.ttf',
	'/fonts/Poppins/Poppins-SemiBold.ttf',
];

function MyApp({ Component, pageProps }: AppProps) {
	// const cookieConfig = useCookieConfig();

	return (
		<>
			<Head>
				<meta name='theme-color' content='#ffffff' media='(prefers-color-scheme: light)' />
				<meta name='theme-color' content='#1d222a' media='(prefers-color-scheme: dark)' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0, minimum-scale=1.0' />

				{fonts.map((x, i) => (
					<link key={i} href={x} rel='preload' as='font' type='font/ttf' crossOrigin='anonymous' />
				))}
			</Head>

			<ThemeProvider
				attribute='class'
				themes={['dark', 'light', 'system']}
				defaultTheme='system'
				enableSystem
				enableColorScheme
			>
				<Suspense>
					{/* <CookieProvider config={cookieConfig}> */}
					<>
						<ScrollProvider>
							{/* @ts-ignore */}
							<Component {...pageProps} />
						</ScrollProvider>
					</>
					{/* </CookieProvider> */}
				</Suspense>
			</ThemeProvider>
		</>
	);
}

export default appWithTranslation(MyApp);
