import { useRouter } from 'next/router';
import { createRef, FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { createCtx } from '.';
import classes from './scroll.module.scss';

type ScrollContextType = {
	scrollRef: React.RefObject<any>;
	scrollToTop: (smooth?: boolean) => void;
	scrollTop: number;
};

const [useScroll, CtxProvider] = createCtx<ScrollContextType>();
export { useScroll };

export const ScrollProvider: FC<{ children: ReactNode }> = (props) => {
	const scrollRef = createRef<Scrollbars>();
	const [scrollTop, setScrollTop] = useState<number>(0);
	const router = useRouter();

	//#region scroll to top on route change
	useEffect(() => {
		scrollToTop(false);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.pathname]);
	//#endregion

	const scrollToTop = (smooth: boolean = true) => {
		(scrollRef.current as any).view.scroll({
			top: 0,
			behavior: smooth ? 'smooth' : 'auto',
		});
	};

	return (
		<CtxProvider value={{ scrollRef, scrollToTop, scrollTop }}>
			{/* @ts-ignore */}
			<Scrollbars
				universal // required for client+server side rendering
				autoHide={true}
				autoHideTimeout={200}
				ref={scrollRef}
				className={classes.scrollbars}
				onScrollFrame={(values) => setScrollTop(values.scrollTop)}
				seamless
				renderThumbVertical={({ style, ...thumbProps }) => (
					<div
						{...thumbProps}
						style={{
							...style,
							backgroundColor: '#bbb',
							borderRadius: '4px',
							cursor: 'pointer',
							width: '9px',
						}}
					/>
				)}
				style={{
					zIndex: 30,
				}}
			>
				{props.children}
			</Scrollbars>
		</CtxProvider>
	);
};
